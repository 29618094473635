import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function Loading() {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <CircularProgress size={60} color="secondary"/>
    </div>
  );
}

export default Loading;