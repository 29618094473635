import AuthResource from "./AuthResource";
import {AbstractInstanceType, EntitySchema, ReadShape, Resource} from "rest-hooks";

export default class RegionLocationLookupResource extends AuthResource {
    readonly success : boolean | null = null;
    readonly id: string | null = null;
    readonly name: string | null = null;

    pk() {
        return "0";
    }

    static detailUrl<T extends typeof Resource>(
        this: T,
        params?: Readonly<any>
    ): string {
        if (!params || !params.latitude || !params.longitude)
            throw Error('missing latitude and longitude params');

        return `${this.urlRoot}?latitude=${params.latitude}&longitude=${params.longitude}`;
    }

    static detailShape<T extends typeof Resource>(
        this: T
    ): ReadShape<EntitySchema<T>> {
        const self = this;
        const options = this.getFetchOptions();
        return {
            type: 'read',
            schema: self.asSchema(),
            options,
            getFetchKey(params: object) {
                return 'GET ' + RegionLocationLookupResource.detailUrl(params);
            },
            fetch(params: Partial<AbstractInstanceType<T>> | undefined) {
                return self.fetch('get', RegionLocationLookupResource.detailUrl(params));
            },
        };
    }

    static urlRoot = "/api/InProgramTrainer/Region";
}