import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    flexDirection: 'column'
  },
  progress: {
    marginBottom: theme.spacing(1)
  }
}));

function LoadingPrograms() {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <CircularProgress size={60} className={classes.progress} color="secondary"/>
      <Typography variant="h6" gutterBottom>Loading programs</Typography>
      {/*TODO: Skeleton loading would be a nice non-essential polish*/}
    </div>
  );
}

export default LoadingPrograms;