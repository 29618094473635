import {Method, Resource} from "rest-hooks";

declare global { interface Window { API_SERVER: string } }

abstract class AuthResource extends Resource {
    static userManager: any | null = null;
    static authToken: string | null = null;

    static fetch(
        method: Method,
        url: string,
        body: object | undefined
    ): Promise<any> {
        if (!AuthResource.userManager) {
            return Promise.reject("Not authenticated 100");
        }
        let userManager = AuthResource.userManager;
        let user = userManager.user;
        if (user) {
            return userManager.getTokenSilently()
                .then((authToken: string) => {
                    AuthResource.authToken = authToken;
                    return super.fetch(method, window.API_SERVER + url, body);
                });
        } else {
            return Promise.reject('User is not logged in');
        }
    }

    static fetchOptionsPlugin = (options: RequestInit) => {
        return {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${AuthResource.authToken}`
            }
        }
    }
}

export default AuthResource;