import AuthResource from "./AuthResource";

export default class UpComingProgramResource extends AuthResource {
    readonly programGuideId: string = "";
    readonly sponsorshipProgramMatchId: string | null = null;
    readonly sponsorshipProgramId: string | null = null;
    readonly name: string | null = null;
    readonly description: string | null = null;
    readonly region: string | null = null;
    readonly channelName: string | null = null;
    readonly startsIn: number | null = null;
    readonly channelId: string | null = null;

    pk() {
        return this.programGuideId;
    }

    static urlRoot = "/api/InProgramTrainer";
}