import React, {Suspense} from 'react';
import {Card, CardActionArea, CardMedia, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Image from "./Image";
import ErrorBoundary from "../../ErrorBoundary";

const useStyles = makeStyles(theme => ({
  actionArea: {
    padding: theme.spacing(2)
  },
  imageContainer: {
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%'
  }
}));

function View({name, logo, onClick}) {
  const classes = useStyles();

  const nameFallback = <Typography variant="h4">{name}</Typography>

  return (
    <Card variant="outlined">
      <CardActionArea className={classes.actionArea} onClick={onClick}>
        <CardMedia className={classes.imageContainer}>
          <Suspense fallback={nameFallback}>
            <ErrorBoundary fallbackComponent={nameFallback}>
              <Image className={classes.image} url={logo} name={name}/>
            </ErrorBoundary>
          </Suspense>
        </CardMedia>
      </CardActionArea>
    </Card>
  );
}

export default View;