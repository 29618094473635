import React from 'react';
import TvRegionResource from "../resources/TvRegionResource";
import {Paper, MenuList, MenuItem} from "@material-ui/core";
import {useResource} from "rest-hooks";

function RegionList({onSelect}) {
  const regions = useResource(TvRegionResource.listShape(), {});

  const handleSelect = region => () => onSelect && onSelect(region.id, region.name);

  return (
    <Paper>
      <MenuList>
        {regions.map(region => <MenuItem key={region.id} onClick={handleSelect(region)}>{region.name}</MenuItem>)}
      </MenuList>
    </Paper>
  );
}

export default RegionList;