import React, {Suspense, useContext, useEffect} from 'react';
import {Redirect, useLocation} from "react-router";
import SponsorshipProgramBrands from "../components/SponsorshipProgramBrands";
import {CircularProgress, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {WakeLockContext} from "../WakeLockContext";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  fallbackContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4)
  },
  textArea: {
    marginBottom: theme.spacing(2)
  }
}));

function Program() {
  const location = useLocation();
  const classes = useStyles();
  const {closeSnackbar} = useSnackbar();

  const noSleep = useContext(WakeLockContext);
  useEffect(() => {
    return () => {
      console.log('Disabled wake lock')
      noSleep.disable();
      closeSnackbar();
    }
  }, []);

  if (!location.state || !location.state.program)
    return <Redirect to="/"/>;
  const {programGuideId, sponsorshipProgramId, name, channelName, region, channelId} = location.state.program;

  return (
    <div className={classes.root}>
      <Suspense fallback={
        <div className={classes.fallbackContainer}>
          <CircularProgress size={60} color="secondary"/>
        </div>
      }>
        <div className={classes.textArea}>
          <Typography variant="h5">{name}</Typography>
          <Typography variant="caption" color="textSecondary" component="p">
            {channelName} • {region}
          </Typography>
        </div>

        <SponsorshipProgramBrands
          programGuideId={programGuideId}
          sponsorshipProgramId={sponsorshipProgramId}
          channelId={channelId}
        />
      </Suspense>
    </div>
  );
}

export default Program;