import { lighten } from '@material-ui/core/styles';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const white = '#ffffff';

const palette = {
  primary: {
    contrastText: white,
    main: '#0f102b',
    light: '#F6F9FD',
    dark: '#0f102b'
  },
  secondary: {
    contrastText: white,
    main: '#ff6f61'
  }
}

export const light = {
  palette: {
    type: 'light',
    ...palette,
    success: {
      contrastText: white,
      main: '#45B880',
      light: '#F1FAF5',
      dark: '#00783E'
    },
    info: {
      contrastText: white,
      main: '#1070CA',
      light: '#F1FBFC',
      dark: '#007489'
    },
    warning: {
      contrastText: white,
      main: '#FFB822',
      light: '#FDF8F3',
      dark: '#95591E'
    },
    danger: {
      contrastText: white,
      main: '#FF6F61',
    },
    error: {
      contrastText: white,
      main: '#FF6F61',
    },
    text: {
      primary: '#303B3B',
      secondary: '#3E5660',
      disabled: '#A6B1BB'
    },
    background: {
      default: lighten('#C3C3C3', 0.7),
      dark: '#172B4D',
      paper: white
    },
    border: '#DFE3E8',
    divider: '#DFE3E8'
  },
  activeCardHeader: green[100],
  inactiveCardHeader: red[100]
};

export const dark = {
  palette: {
    type: 'dark',
    ...palette
  },
  activeCardHeader: green[900],
  inactiveCardHeader: red[900]
};