import React, {useEffect, useState} from "react";
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import Loading from "./pages/Loading";
import Login from "./pages/Login";
import AuthenticatedApp from './AuthenticatedApp';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {light, dark} from "./theme";
import {CacheProvider, NetworkErrorBoundary} from "rest-hooks";
import {SnackbarProvider} from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeContext} from "./ThemeContext";

function App() {
  const {
    user,
    isAuthenticated,
    isLoading: isLoadingAuthentication,
    getAccessTokenSilently
  } = useAuth0();

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => localStorage.setItem('theme', theme), [theme]);

  const themeContext = {
    theme,
    setTheme: x => setTheme(x),
    toggle: () => setTheme(theme === 'dark' ? 'light' : 'dark')
  };

  const muiTheme = createMuiTheme(theme.startsWith('dark') ? dark : light);

  return (
    <ThemeContext.Provider value={themeContext}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline/>
        <SnackbarProvider>
          <CacheProvider>
            <NetworkErrorBoundary>
              {isLoadingAuthentication
                ? <Loading/>
                : isAuthenticated
                  ? <AuthenticatedApp
                      getTokenSilently={getAccessTokenSilently}
                      user={user}
                    />
                  : <Login/>}
            </NetworkErrorBoundary>
          </CacheProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
