import React, {useState} from 'react';
import {useResource} from "rest-hooks";
import SponsorshipProgramBrandResource from "../../resources/SponsorshipProgramBrandResource";
import BrandCard from "./BrandCard";
import {Grid} from "@material-ui/core";
import {Redirect} from "react-router";

function SponsorshipProgramBrands({programGuideId, sponsorshipProgramId, channelId}) {
  const brands = useResource(SponsorshipProgramBrandResource.listShape(), {id: sponsorshipProgramId});
  const [redirect, setRedirect] = useState(false);

  const xs = brands.length > 4 ? 6 : 12;

  return (
    <Grid container spacing={2}>
      {redirect && <Redirect to="/" push/>}
      {brands.map(brand => (
        <Grid item xs={xs} sm={4} md={3} lg={2} key={brand.id}>
          <BrandCard
            brand={brand}
            programGuideId={programGuideId}
            sponsorshipProgramId={sponsorshipProgramId}
            channelId={channelId}
            onBackToAllPrograms={() => setRedirect(true)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default SponsorshipProgramBrands;