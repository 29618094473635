import React, {Fragment, useState} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from "@material-ui/core/styles";
import {useFetcher} from "rest-hooks";
import RegionPostcodeLookupResource from "../resources/RegionPostcodeLookupResource";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    width: 250,
    minHeight: 64
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function PostcodeDialog({open, onClose, onChange}) {
  const classes = useStyles({});
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const lookupPostcode = useFetcher(RegionPostcodeLookupResource.detailShape(), null);

  const handleSubmit = async() => {
    setLoading(true);
    setError(false);
    try {
      const lookupResult = await lookupPostcode({postcode: value}, null);
      if (!lookupResult || !lookupResult.success) {
        setError(true);
        setLoading(false);
      } else {
        const {id, name} = lookupResult;
        onChange(id, name);
      }
    } catch (ex) {
      setError(true);
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">
          Enter postcode
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress color="secondary"/>
          </div>
        ) : (
          <Fragment>
            <TextField
              label="Postcode"
              color="secondary"
              value={value}
              onChange={ev => setValue(ev.target.value)}
              fullWidth
            />
            {error && <FormHelperText error>
              Unable to find region
            </FormHelperText>}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => handleSubmit()}
          disabled={!value}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PostcodeDialog;