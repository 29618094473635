import React, {useCallback, useContext, useEffect, useState} from 'react';
import View from "./View";
import {Button} from "@material-ui/core";
import {useFetcher} from "rest-hooks";
import SponsorshipSeenResource from "../../../resources/SponsorshipSeenResource";
import {useSnackbar} from "notistack";
import {RegionContext} from "../../../RegionContext";

const blobStore = 'https://endpoint879831.azureedge.net/';

function BrandCard({brand, programGuideId, sponsorshipProgramId, channelId, onBackToAllPrograms}) {
  const {name, logo} = brand;
  const sponsorshipSeen = useFetcher(SponsorshipSeenResource.createShape());
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [debounced, setDebounced] = useState(false);
  const [timeoutHandle, setTimeoutHandle] = useState(-1);
  const [snackbarKey, setSnackbarKey] = useState(-1);
  const [undo, setUndo] = useState(false);
  const {id: regionId} = useContext(RegionContext);

  useEffect(() => {
    if (!undo)
      return;

    setUndo(false);

    if (timeoutHandle && timeoutHandle > 0) {
      clearTimeout(timeoutHandle);
      setTimeoutHandle(-1);
    }
    if (snackbarKey && snackbarKey > 0) {
      closeSnackbar(snackbarKey);
      setSnackbarKey(-1);
    }

    setDebounced(false);
  }, [closeSnackbar, snackbarKey, timeoutHandle, undo]);

  const undoCallback = useCallback(() => setUndo(true), []);

  const handleClick = async() => {
    if (debounced)
      return;

    setDebounced(true);

    const snackbar = enqueueSnackbar(`Seen ${brand.name}`, {
      variant: 'success',
      anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
      action: () => (
        <Button color="inherit" onClick={() => undoCallback()}>Undo</Button>
      ),
      autoHideDuration: 5000
    });
    setSnackbarKey(Number(snackbar));

    const timeout = setTimeout(async() => {
      setDebounced(false);
      try {
        const result = await sponsorshipSeen({}, {
          programGuideId,
          sponsorshipProgramId,
          brandId: brand.id,
          regionId,
          channelId
        });
        if (!result.isProgramLive)
          enqueueSnackbar('This program is not currently live', {
            variant: 'error',
            anchorOrigin: {horizontal: 'center', vertical: 'bottom'},
            action: () => (
              <Button color="inherit" onClick={() => onBackToAllPrograms()}>Back to all programs</Button>
            )
          });
      } catch (ex) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'bottom'}
        });
      }
    }, 5000);
    setTimeoutHandle(timeout);
  };

  return (
    <View
      name={name}
      logo={`${blobStore}${logo}`}
      onClick={handleClick}
    />
  );
}

export default BrandCard;