import AuthResource from "./AuthResource";

export default class SponsorshipSeenResource extends AuthResource {
    readonly id: string = '';
    readonly programGuideId: string | null = null;
    readonly sponsorshipProgramId: string | null = null;
    readonly brandId: string | null = null;
    readonly channelId: string | null = null;
    readonly regionId: string | null = null;
    readonly isProgramLive: boolean = true;

    pk() {
        return this.id;
    }

    static urlRoot = "/api/InProgramTrainer/SponsorshipSeen"
}