import React, {useContext} from 'react';
import {useResource} from "rest-hooks";
import UpComingProgramResource from "../../resources/UpComingProgramResource";
import {Grid} from "@material-ui/core";
import ProgramCard from "./ProgramCard";
import {makeStyles} from "@material-ui/core/styles";
import {RegionContext} from "../../RegionContext";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

function ProgramList() {
  const regionContext = useContext(RegionContext);
  const programs = useResource(UpComingProgramResource.listShape(), {region: regionContext.id});
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {programs.map(program => (
          <Grid item xs={12} md={4} lg={3} key={program.programGuideId}>
            <ProgramCard program={program}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ProgramList;