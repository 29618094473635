import React, {useContext, useState} from 'react';
import {Card, CardActionArea, CardContent, Typography, useTheme} from "@material-ui/core";
import ScheduleIcon from '@material-ui/icons/Schedule';
import {makeStyles} from "@material-ui/core/styles";
import {DateTime} from "luxon";
import {Redirect} from "react-router-dom";
import {WakeLockContext} from "../../WakeLockContext";

const useStyles = makeStyles(theme => ({
  timeContainer: {
    paddingBottom: theme.spacing(1)
  },
  mainContainer: {
    paddingTop: theme.spacing(1)
  },
  scheduleIcon: {
    width: 16,
    height: 16,
    verticalAlign: -2,
    marginRight: 2
  }
}));

function ProgramCard({program}) {
  const {name, description, region, channelName, startsIn} = program;
  const classes = useStyles({});
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const noSleep = useContext(WakeLockContext);

  const toSentenceCase = str => str[0].toUpperCase() + str.substr(1);

  const isLive = startsIn < 10;
  const timeUntil = isLive ? 'Now' : DateTime.local().plus({minutes: startsIn}).toRelative();
  const timeBackgroundColor = isLive ? theme.activeCardHeader : theme.inactiveCardHeader;

  const handleClick = () => {
    noSleep.enable();
    console.log('Enabled wake lock');
    setRedirect(true);
  }

  return (
    <Card>
      {redirect && <Redirect to={{pathname: '/program', state: {program}}} push/>}
      <CardActionArea onClick={() => handleClick()}>
        <CardContent className={classes.timeContainer} style={{backgroundColor: timeBackgroundColor}}>
          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
            <ScheduleIcon className={classes.scheduleIcon}/> {toSentenceCase(timeUntil)}
          </Typography>
        </CardContent>
        <CardContent className={classes.mainContainer}>
          <Typography variant="h5" component="h2" gutterBottom>
            {name}
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="p">
            {channelName} • {region}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ProgramCard;