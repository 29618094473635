import AuthResource from "./AuthResource";

export default class TvRegionResource extends AuthResource {
    readonly id: string = '';
    readonly name: string | null = null;

    pk() {
        return this.id;
    }

    static urlRoot = "/api/InProgramTrainer/Regions";
}