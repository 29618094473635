import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";

const API_SERVERS = {
  'localhost': 'http://localhost:63785',
  'adgile-in-program-trainer.web.app': 'https://adgile-portalapi.azurewebsites.net',
  'adgile-in-program-trainer.firebaseapp.com': 'https://adgile-portalapi.azurewebsites.net',
  'trainer.adgile.media': 'https://adgile-portalapi.azurewebsites.net',
  'trainer.adgile.com': 'https://adgile-portalapi.azurewebsites.net'
};


let api_server = API_SERVERS[window.location.hostname.toLowerCase()];
if (api_server) {
  window.API_SERVER = api_server;
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="adgile.au.auth0.com"
      clientId="AxeT7OWZLA0qUCYFwn0F8BO02v5Kfgg6"
      redirectUri={window.location.origin}
      audience={window.API_SERVER}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
