import React, {Suspense, useContext} from 'react';
import ProgramList from '../components/ProgramList';
import LoadingPrograms from "../components/ProgramList/LoadingPrograms";
import {RegionContext} from "../RegionContext";
import {Redirect} from "react-router";

function Landing() {
  const regionContext = useContext(RegionContext);
  const {id: regionId, name: regionName} = regionContext;

  if (!regionId || !regionName)
    return <Redirect to="/location"/>

  return (
    <div>
      <div>
        <Suspense fallback={<LoadingPrograms/>}>
          <ProgramList/>
        </Suspense>
      </div>
    </div>
  );
}

export default Landing;