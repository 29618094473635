import React, {Fragment, Suspense, useCallback, useContext, useEffect, useState} from 'react';
import {useGeolocation} from "../hooks/useGeolocation";
import {Button, CircularProgress, Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LocationIcon from '@material-ui/icons/LocationOn';
import TvIcon from '@material-ui/icons/Tv';
import {useFetcher} from "rest-hooks";
import RegionLocationLookupResource from "../resources/RegionLocationLookupResource";
import {RegionContext} from "../RegionContext";
import {Redirect} from "react-router";
import RegionList from "../components/RegionList";
import PostcodeDialog from "../components/PostcodeDialog";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    paddingTop: theme.spacing(8)
  },
  tvIcon: {
    width: 100,
    height: 100,
    color: theme.palette.text.secondary
  },
  iconCopy: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(6)
  },
  locationButton: {
    marginBottom: theme.spacing(4)
  },
  listContainer: {
    marginBottom: theme.spacing(4)
  },
  errorText: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(4)
  },
  postcodeButton: {
    marginBottom: theme.spacing(2)
  }
}));

function Location() {
  const {position, error: locationError, request: requestLocation} = useGeolocation(true);
  const lookupRegion = useFetcher(RegionLocationLookupResource.detailShape(), null);
  const regionContext = useContext(RegionContext);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [lookupError, setLookupError] = useState(false);
  const [postcodeDialogOpen, setPostcodeDialogOpen] = useState(false);
  const classes = useStyles();

  const handleRequestLocation = useCallback(() => {
    setLoading(true);
    requestLocation();
  }, [requestLocation]);

  useEffect(() => {
    if (!locationError)
      return;

    setLoading(false);
    setShowList(true);
  }, [locationError]);

  const changeRegion = useCallback((id, name) => {
    regionContext.update(id, name);
    setRedirect('/');
  }, [regionContext]);

  useEffect(() => {
    const doWork =  async() => {
      const tvRegion = await lookupRegion({...position}, null);
      if (!tvRegion || !tvRegion.success) {
        setLoading(false);
        setShowList(true);
        setLookupError(true);
        return;
      }
      changeRegion(tvRegion.id, tvRegion.name);
    };
    if (position.latitude && position.longitude) {
      doWork().then(() => null);
    }
  }, [changeRegion, lookupRegion, position]);

  const showLocationError = locationError || lookupError;

  return (
    <Container maxWidth="sm" className={classes.container}>
      {redirect && <Redirect to={redirect}/>}
      <TvIcon color="action" className={classes.tvIcon}/>
      <Typography variant="h5" className={classes.iconCopy}>
        Where are you watching?
      </Typography>

      {loading ? (
        <CircularProgress size={60} color="secondary"/>
      ) : (
        <Fragment>
          {showLocationError ? (
            <Typography variant="caption" className={classes.errorText} component="p">
              Your location couldn't be determined
            </Typography>
          ) : (
            <div>
              <Button
                variant="contained"
                size="large"
                startIcon={<LocationIcon/>}
                className={classes.locationButton}
                onClick={handleRequestLocation}
              >
                Use my location
              </Button>
            </div>
          )}
          <div>
            <Button
              variant="text"
              size="small"
              onClick={() => setPostcodeDialogOpen(true)}
              className={classes.postcodeButton}
            >
              Enter postcode
            </Button>
          </div>
          {!showList && (
            <div>
              <Button
                variant="text"
                size="small"
                onClick={() => setShowList(true)}
              >
                Select from a list
              </Button>
            </div>
          )}
          {showList && (
            <Container maxWidth="xs" className={classes.listContainer}>
              <Suspense fallback={<CircularProgress size={60} color="secondary"/>}>
                <RegionList onSelect={(id, name) => changeRegion(id, name)}/>
              </Suspense>
            </Container>
          )}
        </Fragment>
      )}
      <PostcodeDialog
        open={postcodeDialogOpen}
        onClose={() => setPostcodeDialogOpen(false)}
        onChange={(id, name) => changeRegion(id, name)}
      />
    </Container>
  );
}

export default Location;