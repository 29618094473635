import AuthResource from "./AuthResource";
import {AbstractInstanceType, EntitySchema, ReadShape, Resource} from "rest-hooks";

export default class RegionPostcodeLookupResource extends AuthResource {
    readonly success : boolean | null = null;
    readonly id: string | null = null;
    readonly name: string | null = null;

    pk() {
        return "0";
    }

    static detailUrl<T extends typeof Resource>(
        this: T,
        params?: Readonly<any>
    ): string {
        if (!params || !params.postcode)
            throw Error('missing postcode param');

        return `${this.urlRoot}/${params.postcode}`;
    }

    static detailShape<T extends typeof Resource>(
        this: T
    ): ReadShape<EntitySchema<T>> {
        const self = this;
        const options = this.getFetchOptions();
        return {
            type: 'read',
            schema: self.asSchema(),
            options,
            getFetchKey(params: object) {
                return 'GET ' + RegionPostcodeLookupResource.detailUrl(params);
            },
            fetch(params: Partial<AbstractInstanceType<T>> | undefined) {
                return self.fetch('get', RegionPostcodeLookupResource.detailUrl(params));
            },
        };
    }

    static urlRoot = "/api/InProgramTrainer/RegionFromPostcode";
}