import React from 'react';
import {useImage} from "react-image";

function Image({className, name, url}) {
  const {src} = useImage({
    srcList: url
  });

  return (
    <img className={className} src={src} alt={name}/>
  );
}

export default Image;