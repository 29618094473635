import React, {Fragment, Suspense, useState} from 'react';
import {Toolbar} from "@material-ui/core";
import AppBar from "./components/AppBar";
import AuthResource from './resources/AuthResource';
import {RegionContext} from "./RegionContext";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Location from "./pages/Location";
import Landing from "./pages/Landing";
import Program from "./pages/Program";
import NoSleep from "nosleep.js";
import {WakeLockContext} from "./WakeLockContext";

function AuthenticatedApp({getTokenSilently, user}) {
  const [regionId, setRegionId] = useState(localStorage.getItem('regionId'));
  const [regionName, setRegionName] = useState(localStorage.getItem('regionName'));
  const [noSleep] = useState(new NoSleep());

  AuthResource.userManager = {
    getTokenSilently,
    user
  };

  const regionContext = {
    id: regionId,
    name: regionName,
    update: (id, name) => {
      localStorage.setItem('regionId', id);
      localStorage.setItem('regionName', name);
      setRegionId(id);
      setRegionName(name);
    }
  };

  return (
    <Fragment>
      <RegionContext.Provider value={regionContext}>
        <WakeLockContext.Provider value={noSleep}>
          <Suspense fallback="Loading">
            <Router>
              <AppBar name={user.name}/>
              <Toolbar/>
              <Switch>
                <Route path="/location">
                  <Location/>
                </Route>
                <Route path="/program">
                  <Program/>
                </Route>
                <Route path="/">
                  <Landing/>
                </Route>
              </Switch>
            </Router>
          </Suspense>
        </WakeLockContext.Provider>
      </RegionContext.Provider>
    </Fragment>
  );
}

export default AuthenticatedApp;