import AuthResource from "./AuthResource";

export default class SponsorshipProgramBrandResource extends AuthResource {
    readonly id: string = '';
    readonly name: string | null = null;
    readonly logo: string | null = null;

    pk() {
        return this.id;
    }

    static listUrl(params:any): string {
        return `${this.urlRoot}/${params.id}/Brands`;
    }

    static urlRoot = "/api/InProgramTrainer/SponsorshipProgram";
}