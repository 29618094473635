import React, {Fragment, useContext, useState} from 'react';
import {
  AppBar as MuiAppBar,
  Button,
  IconButton,
  Menu,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import WhiteBoltImage from '../images/AdgileBolt_RGB_White.png';
import {useAuth0} from "@auth0/auth0-react";
import InputIcon from '@material-ui/icons/Input';
import {RegionContext} from "../RegionContext";
import LocationIcon from '@material-ui/icons/LocationOn';
import {Link, useLocation} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';
import {ThemeContext} from "../ThemeContext";
import MoreIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#3e5660'
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  name: {
    marginRight: theme.spacing(1)
  },
  bolt: {
    width: 36,
    height: 36
  },
  location: {
    marginLeft: theme.spacing(2)
  },
  locationIcon: {
    width: 18,
    height: 18,
    verticalAlign: -5,
    marginRight: 4
  },
  menuItemIcon: {
    minWidth: 36
  }
}));

function AppBar({name}) {
  const theme = useTheme();
  const classes = useStyles();
  const regionContext = useContext(RegionContext);
  const themeContext = useContext(ThemeContext);
  const wide = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();
  const showBack = location.pathname.toLowerCase().startsWith('/program');
  const collapseMenu = useMediaQuery(theme.breakpoints.down('xs'));


  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const handleMenuOpen = event => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const toggleTheme = () => {
    handleMenuClose();
    themeContext.toggle();
  }

  const {logout} = useAuth0();
  const handleLogout = () => {
    handleMenuClose();
    logout({ returnTo: window.location.origin });
  }

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {showBack ? (
          <Button component={Link} to="/" variant="text" startIcon={<ArrowBackIcon/>} color="inherit">
            All Programs
          </Button>
        ) : (
          <Fragment>
            <img src={WhiteBoltImage} className={classes.bolt} alt="Adgile Media"/>
            {wide && (
              <Typography variant="h6" className={classes.title}>
                In-Program Trainer
              </Typography>
            )}
            {regionContext.name && <Link to="/location" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="caption" className={classes.location}>
                <LocationIcon className={classes.locationIcon}/>
                {regionContext.name}
              </Typography>
            </Link>}
          </Fragment>
        )}

        <div className={classes.spacer}/>

        {collapseMenu ? (
          <Fragment>
            <IconButton color="inherit">
              <MoreIcon onClick={handleMenuOpen}/>
            </IconButton>
            <Menu
              open={Boolean(menuAnchorEl)}
              keepMounted
              onClose={handleMenuClose}
              anchorEl={menuAnchorEl}
            >
              <MenuItem disabled dense>
                <Typography variant="caption">
                  Logged in as {name}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => toggleTheme()}>
                <ListItemIcon className={classes.menuItemIcon}>
                  {themeContext.theme === 'dark' ? <LightIcon/> : <DarkIcon/>}
                </ListItemIcon>
                Toggle theme
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <InputIcon/>
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="body2" className={classes.name}>
              {name}
            </Typography>
            <IconButton color="inherit" onClick={handleLogout}>
              <InputIcon/>
            </IconButton>
            <IconButton color="inherit" onClick={() => toggleTheme()}>
              {themeContext.theme === 'dark'
                ? <LightIcon/>
                : <DarkIcon/>
              }
            </IconButton>
          </Fragment>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;