import {useState, useEffect, useCallback} from 'react';

export const useGeolocation = (wait) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(false);

  const onSuccess = ({coords}) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  const onError = () => {
    setError(true);
  };

  const request = useCallback(() => {
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, [])

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo)
      setError(true);

    if (!wait)
      request();
  }, [request, wait]);

  return {position, error, request};
}